import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { PAGES_ENUM } from '../../../../utils/constants';
import styles from './About.module.scss';

export const About = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.about}>
      <MaxWidthContainer>
        <div className="row g-0 justify-content-end">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-12">
            <h2 className={styles.aboutTitle}>{t('home.about.title')}</h2>
            <p className={styles.aboutDescription}>
              Every activity on our platform is designed to be enjoyable and interactive. From
              solving puzzles to playing games with favorite characters, your child will stay
              interested and motivated to learn!
            </p>
            <h3 className={styles.aboutSubtitle}>Digital Art Space</h3>
            <p className={styles.aboutDescription}>
              With KidCanvas your child can create anything they imagine, right on the tablet—no
              mess, just fun! It&apos;s super easy to use, and perfect for keeping kids busy and
              creative, whether at home or on the go. And the best part? It’s completely free to
              use!
            </p>
            <h3 className={styles.aboutSubtitle}>Online Workbooks </h3>
            <p className={styles.aboutDescription}>
              Our workbooks are more than just pages - they&apos;re learning adventures! Covering
              subjects like reading, math, logic, and more, your child can learn at their own pace
              on any device. They can be opened online in KidCanvas, allowing your child to complete
              tasks using the same fun tools. No printing is required. The changes are saved
              automatically, so you can always come back and check your child’s progress or start
              fresh. Perfect for families with more than one child!
            </p>
            <h3 className={styles.aboutSubtitle}>Online Games</h3>
            <p className={styles.aboutDescription}>
              Our online games are designed to make learning interactive and enjoyable —all with a
              swipe of a finger! The games are accessible directly online, so no downloads are
              needed. And they are free!
            </p>
            <h3 className={styles.aboutSubtitle}>Play & Learn Anywhere and Anytime!</h3>
            <p className={styles.aboutDescription}>
              No matter where you are, learning will fit into your day. Our workbooks, Kidcanvas and
              games let your child learn everywhere - convenient for busy parents and fun for kids!
              <br /><br />
              Ready to get started? Choose the activity and start exploring!
            </p>

            <div className={styles.aboutBtnsWrapper}>
              <LinkButton
                className={styles.aboutBtn}
                to={PAGES_ENUM.PRODUCTS}
                isOrange
                isTargetBlank
              >
                {t('home.about.onlineBooksBtnText')}
              </LinkButton>
              <LinkButton
                className={styles.aboutBtn}
                link={process.env.REACT_APP_EDITOR_HOST}
                isTargetBlank
                isBlue
              >
                Draw Online
              </LinkButton>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
