import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { PAGES_ENUM } from '../../../../utils/constants';
import styles from './HomeBanner.module.scss';

export const HomeBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.homeBanner}>
      <MaxWidthContainer>
        <div className="row g-0">
          <div className="col-lg-9 col-sm-11">
            <div className={styles.homeBannerContent}>
              <h3 className={styles.homeBannerSubtitle}>{t('home.banner.subtitle')}</h3>
              <h1 className={styles.homeBannerTitle}>{t('home.banner.title')}</h1>
              <p className={styles.homeBannerDescription}>{t('home.banner.description')}</p>
              <div className={styles.homeBannerBtnBox}>
                <LinkButton to={PAGES_ENUM.PRODUCTS} className={styles.homeBannerBtn} isOrange>
                  {t('home.banner.btn')}
                </LinkButton>

                {/* <LinkButton to={PAGES_ENUM.AMAZON_WORKBOOKS} className={styles.homeBannerBtn}>
                  {t('home.banner.btn2')}
                </LinkButton> */}
              </div>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
